<template>
  <span>
    <v-app id="inspire">
      <v-content id="content">
        <div class="d-flex justify-center align-center fill-height">
          <v-card
            class="elevation-12"
            id="cardLogin"
            :style="{width : $vuetify.breakpoint.smAndDown ? '85%' : '35%'}"
          >
            <v-toolbar color="transparent" flat>
              <v-spacer></v-spacer>
              <v-toolbar-title class="black--text">Módulo de Administrador</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text class="pb-0">
              <v-form>
                <v-text-field
                  v-model="user.usuario"
                  label="Usuario"
                  placeholder="Usuario"
                  name="login"
                  prepend-icon="mdi-account"
                  type="text"
                  filled
                  rounded
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="user.password"
                  label="Contraseña"
                  placeholder="Contraseña"
                  name="password"
                  prepend-icon="mdi-lock"
                  type="password"
                  filled
                  rounded
                  dense
                ></v-text-field>
              </v-form>
              <v-alert
                border="left"
                close-text="Close Alert"
                type="error"
                v-model="isErrorValidation"
              >{{AuthenticationError}}</v-alert>
              <v-alert
                border="left"
                close-text="Close Alert"
                type="error"
                v-model="getAuthError.error"
              >{{getAuthError.authErrorMessage}}</v-alert>
            </v-card-text>
            <v-card-actions>
              <v-btn
                block
                dark
                color="primary"
                :loading="authenticating"
                @click="loginAdminBtn"
              >Login</v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-content>
    </v-app>
  </span>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    source: String
  },
  data: () => ({
    drawer: null,
    user: {}
  }),
  methods: {
    ...mapActions("auth", ["loginAdmin"]),

    loginAdminBtn: async function() {
      let response = await this.loginAdmin(this.user);
    }
  },

  computed: {
    ...mapGetters("auth", [
      "authenticating",
      "AuthenticationError",
      "AuthenticationErrorCode"
    ]),

    ...mapGetters("user", ["getAuthError"]),

    isErrorValidation() {
      return this.AuthenticationErrorCode != 0;
    }
  }
};
</script>

<style>
#content {
  background-image: url(../../assets/admin_login.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

#cardLogin {
  opacity: 0.75;
}
</style>
